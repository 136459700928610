import logo from './logo.svg';
import './App.css';

import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Chooser from './pages/Chooser';
import Confirm from './pages/Confirm';
import MakePayment from './pages/MakePayment';


function App() {
  return (
    <>
<BrowserRouter>
        <Routes>


        <Route path='/' element={<Chooser />}></Route>
        <Route path='/confirm/:param1' element={<Confirm />}></Route>
        <Route path='/make-payment/:param1' element={<MakePayment />}></Route>


          {/* <Route path='*' element={<PageNotFound />}></Route> */}


        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
