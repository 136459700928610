import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../pages-css/Chooser.css";
import links from "../links-data/data.json";

export default function Chooser() {
  const [filter, setFilter] = useState("");

  const filteredLinks = links.filter((link) =>
    link.filename.toLowerCase().includes(filter.toLowerCase())
  );

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  return (
    <>
      <center>
        <input
          type="text"
          placeholder="Enter One Subject Code here (BEGS-101...)"
          value={filter}
          onChange={handleFilterChange}
          className="border rounded p-2 m-3 w-75"
        />

        <div id="product-scroller">
          {filteredLinks.map((link, index) => (
            <div key={index}>
              <Link
                to={"/confirm/" + btoa(JSON.stringify(link))}
                className="btn border py-3 px-2 m-1 w-75 bg-primary text-white"
              >
                {link.filename}
              </Link>
            </div>
          ))}
        </div>

        <a
          id="help-btn"
          className="btn bg-danger w-75 px-2 py-3 text-white"
          onClick={() => {
            openYoutube("link to the helping video");
          }}
        >
          <b>How to Buy ?</b>
        </a>
      </center>
    </>
  );
}

function openYoutube() {}
