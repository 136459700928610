import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import "../pages-css/MakePayment.css";
import loader from "../images/bookshelf-loader.gif";
import React, { useState } from "react";
import discount from "../images/10-discount.png";

var orderData;

export default function MakePayment() {
  const { param1 } = useParams();
  const [textColor, setTextColor] = useState("text-secondary"); // Initial color is black

  return (
    <div className="p-4">
      <h1 className="text-secondary text-center mb-3 p-3">
        <strong>Place Order</strong>
      </h1>

      <center>
        <img
          width="32"
          height="32"
          src="https://img.icons8.com/cute-clipart/64/name.png"
          alt="name"
          className="align-medium"
        />
        <input
          className="border rounded w-75 m-2 p-2 text-center"
          id="uname"
          placeholder="Username"
          required
        />
        <br />
        <img
          className="align-middle"
          width="32"
          height="32"
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAADT0lEQVR4nO2X3UsUURjGX4uCUrspqIuCNAW7CAqhIs/sWJa3WUT/QEnSdWaIsEFBUOiMVkJQF3UhhTflBxVIfmzknlmFJLsI6aI7Q8ywcs8R841zdlFbd2Z2Z7ezS8wDDwyz877n+c2ec2YGwJcvX77+G6EOO5BAPRJ4gRp8QgI/pWPH4txFrIHtkG/CU1CIGlxHAj9QA3TxPBIIYiVshXwQarAHCYynEDzRE6hBSW7DEyhFDb56CB8zgemcQeAxKEYNPnoOvwrxAXUoUg9A4FbG4bUViBtqw+uwCzVYcAj0HAnocnELV0E1atDjAPELj8BOdQAELjuEb3Koa3aAaFAHoMFLuzufQm2vTW2/mvSxEJ9tQuiutQE4blM7pRIg+fxPYTeJ715J1wGoEhKY8wxwGLbZAMyq/AdCSUNUQXUKtSdsAEJq0sdCtNiE6HGtJdBnU9ui8jmwGwks2SzG5rTBCSyJnsoAZJjY67Hdnt4rdxsdiqTFtLG/8yh6KQ0vATSozdqrhAa1ygHiEINZCB/KSXgJUAUkYwDi/vD7txBOc9s9fF9Ow0uAAJQjAeYBgKMOFZAPQg1u5v03gJPwKGyxfcFL7i/iOwFyqeAkbm4PL1YalNebFm991floeDlQ4BpeXPO68+GQqDEorxc9RC8loY1Rtt+grMmgLGRQtmhaHNd6oumSK8DEtYa/akyLY6wXGzHD7KoYI6uhg4gb2iLsnAidOHCi772dw7kzZbbhv9eV4P3QrGMPU5qNGBY7i4gFGYVvH4vuMy027D7gqp91D+NyYOP6qaNvwO6nAyn3MYUpH2yzoqWewneEoyWGxafTGjDuSPDKOoBIsDHtPqaEYDOt49GytAHknPQyoMXx7rt5nDl/YCW8OBbnvPYzKBvyArDgdUDhx/2TyE8W42JNIT7pfe+5jxlbEwtpAwjqzAblYruUzrSPYfE36QOMRvd6XQNZNWUzYjNJG2ANxEDuANiIOcrKIROJvdgIs7psTKmUpwxlQ+0Rdjrj50CiOsZYhWGxRglDGc9iYCb2fNFbjAEq9GAMN7Vb/JAZ5hcMyu+YlHXJpzXlU2LtGBb7ZlL2W1gcy/UkfpPXsC6T8tvx2oOil5LQvnz58gXZ1h8JC/UEAA50vAAAAABJRU5ErkJggg=="
        ></img>
        <select
          className={`border rounded w-75 m-2 p-2 bg-white text-center ${textColor}`}
          id="state"
          required
          onClick={() => {
            setTextColor("black");
          }}
        >
          <option value="">Select State or UT</option>
          <option value="Andhra Pradesh">Andhra Pradesh</option>
          <option value="Arunachal Pradesh">Arunachal Pradesh</option>
          <option value="Assam">Assam</option>
          <option value="Bihar">Bihar</option>
          <option value="Chhattisgarh">Chhattisgarh</option>
          <option value="Goa">Goa</option>
          <option value="Gujarat">Gujarat</option>
          <option value="Haryana">Haryana</option>
          <option value="Himachal Pradesh">Himachal Pradesh</option>
          <option value="Jharkhand">Jharkhand</option>
          <option value="Karnataka">Karnataka</option>
          <option value="Kerala">Kerala</option>
          <option value="Madhya Pradesh">Madhya Pradesh</option>
          <option value="Maharashtra">Maharashtra</option>
          <option value="Manipur">Manipur</option>
          <option value="Meghalaya">Meghalaya</option>
          <option value="Mizoram">Mizoram</option>
          <option value="Nagaland">Nagaland</option>
          <option value="Odisha">Odisha</option>
          <option value="Punjab">Punjab</option>
          <option value="Rajasthan">Rajasthan</option>
          <option value="Sikkim">Sikkim</option>
          <option value="Tamil Nadu">Tamil Nadu</option>
          <option value="Telangana">Telangana</option>
          <option value="Tripura">Tripura</option>
          <option value="Uttar Pradesh">Uttar Pradesh</option>
          <option value="Uttarakhand">Uttarakhand</option>
          <option value="West Bengal">West Bengal</option>
          <option value="Andaman and Nicobar Islands">
            Andaman and Nicobar Islands
          </option>
          <option value="Chandigarh">Chandigarh</option>
          <option value="Dadra and Nagar Haveli and Daman and Diu">
            Dadra and Nagar Haveli and Daman and Diu
          </option>
          <option value="Lakshadweep">Lakshadweep</option>
          <option value="Delhi">
            Delhi (National Capital Territory of Delhi)
          </option>
          <option value="Puducherry">Puducherry</option>
          <option value="Jammu and Kashmir">Jammu and Kashmir</option>
          <option value="Ladakh">Ladakh</option>
        </select>
        <br />
        <img
          width="28"
          height="28"
          src="https://img.icons8.com/pastel-glyph/64/40C057/whatsapp--v2.png"
          className="align-middle"
          alt="whatsapp"
        />
        <input
          className="border rounded w-75 m-2 p-2 text-center"
          id="whatsApp"
          type="tel"
          maxLength={10}
          required
          placeholder="10 Digit Whatsapp Number"
        />
        <br />
        <button
          id="send-qr-btn"
          className="btn rounded mt-5 m-2 py-2 px-3 text-white bg-success"
          onClick={() => {
            initPayment(param1);
          }}
        >
          <strong>Make Payment</strong>
        </button>
      </center>

      {/* Progress Showing Window */}
      <div id="progress-notice-window" className="shadow rounded">
        <a
          className="btn"
          onClick={() => {
            closeHandleResponseWindow();
          }}
        >
          <img
            width={35}
            height={35}
            src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iNTAiIGhlaWdodD0iNTAiIHZpZXdCb3g9IjAgMCA1MCA1MCIKc3R5bGU9ImZpbGw6IzQwQzA1NzsiPgo8cGF0aCBkPSJNMjUsMkMxMi4zMTksMiwyLDEyLjMxOSwyLDI1czEwLjMxOSwyMywyMywyM3MyMy0xMC4zMTksMjMtMjNTMzcuNjgxLDIsMjUsMnogTTMzLjcxLDMyLjI5YzAuMzksMC4zOSwwLjM5LDEuMDMsMCwxLjQyCUMzMy41MSwzMy45LDMzLjI2LDM0LDMzLDM0cy0wLjUxLTAuMS0wLjcxLTAuMjlMMjUsMjYuNDJsLTcuMjksNy4yOUMxNy41MSwzMy45LDE3LjI2LDM0LDE3LDM0cy0wLjUxLTAuMS0wLjcxLTAuMjkJYy0wLjM5LTAuMzktMC4zOS0xLjAzLDAtMS40MkwyMy41OCwyNWwtNy4yOS03LjI5Yy0wLjM5LTAuMzktMC4zOS0xLjAzLDAtMS40MmMwLjM5LTAuMzksMS4wMy0wLjM5LDEuNDIsMEwyNSwyMy41OGw3LjI5LTcuMjkJYzAuMzktMC4zOSwxLjAzLTAuMzksMS40MiwwYzAuMzksMC4zOSwwLjM5LDEuMDMsMCwxLjQyTDI2LjQyLDI1TDMzLjcxLDMyLjI5eiI+PC9wYXRoPgo8L3N2Zz4="
          />
        </a>

        <center>
          <h1 className="text-center text-success p-2">Success</h1>
          <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iNjQiIGhlaWdodD0iNjQiIHZpZXdCb3g9IjAgMCA2NCA2NCIKc3R5bGU9ImZpbGw6IzQwQzA1NzsiPgo8cGF0aCBkPSJNMzIsNkMxNy42NDEsNiw2LDE3LjY0MSw2LDMyYzAsMTQuMzU5LDExLjY0MSwyNiwyNiwyNnMyNi0xMS42NDEsMjYtMjZDNTgsMTcuNjQxLDQ2LjM1OSw2LDMyLDZ6IE0yOSw0MkwxOCwzMWwyLTNsOSw2IGwxMy45NTctMTJMNDYsMjVMMjksNDJ6Ij48L3BhdGg+Cjwvc3ZnPg==" />
          <br />

          <a
            className="btn btn-success border rounded p-2 mt-4"
            onClick={() => {
              whatsappOrderOnSuccess();
            }}
          >
            <b>Open WhatsApp to Proceed</b>
          </a>
        </center>
      </div>

      {/* Error Showing Window */}
      <div id="error-notice-window" className="shadow rounded">
        <a
          className="btn"
          onClick={() => {
            closeHandleErrorWindow();
          }}
        >
          <img
            width={35}
            height={35}
            src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iNTAiIGhlaWdodD0iNTAiIHZpZXdCb3g9IjAsMCwyNTYsMjU2IgpzdHlsZT0iZmlsbDojMDAwMDAwOyI+CjxnIGZpbGw9IiNmY2M0MTkiIGZpbGwtcnVsZT0ibm9uemVybyIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIHN0cm9rZS1saW5lY2FwPSJidXR0IiBzdHJva2UtbGluZWpvaW49Im1pdGVyIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS1kYXNoYXJyYXk9IiIgc3Ryb2tlLWRhc2hvZmZzZXQ9IjAiIGZvbnQtZmFtaWx5PSJub25lIiBmb250LXdlaWdodD0ibm9uZSIgZm9udC1zaXplPSJub25lIiB0ZXh0LWFuY2hvcj0ibm9uZSIgc3R5bGU9Im1peC1ibGVuZC1tb2RlOiBub3JtYWwiPjxnIHRyYW5zZm9ybT0ic2NhbGUoNS4xMiw1LjEyKSI+PHBhdGggZD0iTTI1LDJjLTEyLjY4MSwwIC0yMywxMC4zMTkgLTIzLDIzYzAsMTIuNjgxIDEwLjMxOSwyMyAyMywyM2MxMi42ODEsMCAyMywtMTAuMzE5IDIzLC0yM2MwLC0xMi42ODEgLTEwLjMxOSwtMjMgLTIzLC0yM3pNMzMuNzEsMzIuMjljMC4zOSwwLjM5IDAuMzksMS4wMyAwLDEuNDJjLTAuMiwwLjE5IC0wLjQ1LDAuMjkgLTAuNzEsMC4yOWMtMC4yNiwwIC0wLjUxLC0wLjEgLTAuNzEsLTAuMjlsLTcuMjksLTcuMjlsLTcuMjksNy4yOWMtMC4yLDAuMTkgLTAuNDUsMC4yOSAtMC43MSwwLjI5Yy0wLjI2LDAgLTAuNTEsLTAuMSAtMC43MSwtMC4yOWMtMC4zOSwtMC4zOSAtMC4zOSwtMS4wMyAwLC0xLjQybDcuMjksLTcuMjlsLTcuMjksLTcuMjljLTAuMzksLTAuMzkgLTAuMzksLTEuMDMgMCwtMS40MmMwLjM5LC0wLjM5IDEuMDMsLTAuMzkgMS40MiwwbDcuMjksNy4yOWw3LjI5LC03LjI5YzAuMzksLTAuMzkgMS4wMywtMC4zOSAxLjQyLDBjMC4zOSwwLjM5IDAuMzksMS4wMyAwLDEuNDJsLTcuMjksNy4yOXoiPjwvcGF0aD48L2c+PC9nPgo8L3N2Zz4="
          />
        </a>

        <center>
          {/* Error From Backend Occurs */}
          <h1 className="text-center text-warning p-2">
            <strong>Lucky User</strong>
          </h1>
          <img src={discount} width={150} />
          <br />

          <a
            className="btn btn-warning border rounded p-2 mt-4 text-white"
            onClick={() => {
              whatsappOrderLuckUser(param1);
            }}
          >
            <b>Place Order through WhatsApp</b>
          </a>
        </center>
      </div>

      {/* Skeleton Showing Window */}
      <div id="progress-skeleton-window" className="shadow rounded">
        <img id="loader" src={loader} />

        {/* <a className="btn-skeleton"></a>

        <center>
          <h1
            id="skeleton-status"
            className="text-center p-2 w-75 text-secondary"
          >
            .
          </h1>
          <img id="skeleton-logo" />
          <br />

          <a className="btn btn-success border rounded p-2 mt-4">
            <b>Open WhatsApp to Proceed</b>
          </a>
        </center> */}
      </div>
    </div>
  );
}

function initPayment(encodedData) {
  let uname = String(document.getElementById("uname").value); // .toString().trim().slice(-10)
  let state = String(document.getElementById("state").value); // .toString().trim().slice(-10)
  let whatsapp = String(document.getElementById("whatsApp").value); // .toString().trim().slice(-10)

  let customer = btoa(
    JSON.stringify({ uname: uname, state: state, whatsapp: whatsapp })
  );

  let order = encodedData;

  if (
    uname != null &&
    state != "" &&
    state != null &&
    whatsapp.length == 10 &&
    JSON.parse(atob(order)).id != null
  ) {
    // Start Skeleton Showing
    showSkeleton();

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    var urlencoded = new URLSearchParams();
    urlencoded.append("customer", customer);
    urlencoded.append("order", order);

    const controller = new AbortController();
    const timeout = 16000; // 10 seconds

    const timer = setTimeout(() => {
      controller.abort();
    }, timeout);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
      signal: controller.signal,
    };

    //For Error Case
    orderData = urlencoded;

    fetch("https://api-chatbot.ignouplus.com/init-payment", requestOptions)
      .then((response) => {
        clearTimeout(timer);
        return response.text();
      }) // Added "return" here
      .then((result) => {
        handleResponse(String(result));
        console.log(result);
      })
      .catch((error) => handleError())
      .finally(() => closeSkeleton());
  } else {
    alert("Please Fill the Details Correctly");
  }
}

function handleResponse(res) {
  if (res == "true") {
    let progressNotice = document.getElementById("progress-notice-window");
    progressNotice.style.display = "block";

    let sendQrBtn = document.getElementById("send-qr-btn");
    sendQrBtn.textContent = "Resend QR on WhatsApp";
  } else {
    handleError();
  }
}

function handleError() {
  let errorNotice = document.getElementById("error-notice-window");
  errorNotice.style.display = "block";
}

function closeHandleResponseWindow() {
  document.getElementById("progress-notice-window").style.display = "none";
}
function closeHandleErrorWindow() {
  document.getElementById("error-notice-window").style.display = "none";
}

function showSkeleton() {
  let skeleton = document.getElementById("progress-skeleton-window");
  skeleton.style.display = "block";
}

function closeSkeleton() {
  let skeleton = document.getElementById("progress-skeleton-window");
  skeleton.style.display = "none";
}

function whatsappOrderOnSuccess() {
  try {
    window.Android.openWhatsApp("9317126842", " ");
  } catch (e) {}
}

function whatsappOrderLuckUser(encodedOrderData) {
  if (encodedOrderData != null) {
    let msg = encodedOrderData;
    try {
      window.Android.openWhatsApp("9317126842", msg);
    } catch (e) {}
  }
}
